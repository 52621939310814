<template>
  <div>
    <div class="card border-0">
      <form @submit.prevent="" class="w-100 mb-2">
        <div class="row">
          <div class="col-12 text-right">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel
              </button>
              <button class="e-btn e-btn-blue ml-2" type="submit" @click="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
            </template>
            <button class="e-btn e-btn-green ml-2" type="button" v-else @click="toEdit = true">Edit Attachments</button>
          </div>
        </div>
      </form>
      <table>
        <tr v-if="toEdit">
          <td class="text-left">
            <div>
              <input hidden="hidden" type="file" ref="before_attachments"  @change="addImage('before')" name="before_attachments" id="before_attachments" accept="image/*" multiple>
              <button class="e-btn e-btn-green mt-1 mb-2 e-text-white mx-1" @click="$refs.before_attachments.click()">
                <font-awesome-icon icon="plus"/>
                Add Attachment
              </button>
            </div>
          </td>
          <td class="text-left">
            <div>
              <input hidden="hidden" type="file" ref="after_attachments"  @change="addImage('after')" name="after_attachments" id="after_attachments" accept="image/*" multiple>
              <button class="e-btn e-btn-green mt-1 mb-2 e-text-white mx-1" @click="$refs.after_attachments.click()">
                <font-awesome-icon icon="plus"/>
                Add Attachment
              </button>
            </div>
          </td>
        </tr>
      </table>
      <table class="table table-sm table-bordered">
        <thead class="text-center">
        <tr>
          <th class="align-middle" style="width: 50%">BEFORE</th>
          <th class="align-middle">AFTER</th>
        </tr>
        </thead>
        <tr>
          <td>
              <div style="max-height: 60vh; overflow-y: auto">
                <template v-for="(image, index) in attachments.before.images">
                  <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                       @dblclick="viewImage(index, attachments.before.images, {
                         'path'      : 'src',
                         'caption'   : 'name'
                       })">
                    <div class="p-2">
                      <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                        <span v-if="toEdit" class="cursor-pointer close-selected-image" aria-hidden="true" @click.stop="deleteImage('before', index)">&times;</span>
                        <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.src + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
          </td>
          <td>
            <div style="max-height: 60vh; overflow-y: auto">
              <template v-for="(image, index) in attachments.after.images">
                <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                     @dblclick="viewImage(index, attachments.after.images, {
                         'path'      : 'src',
                         'caption'   : 'name'
                       })">
                  <div class="p-2">
                    <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                      <span v-if="toEdit" class="cursor-pointer close-selected-image" aria-hidden="true" @click.stop="deleteImage('after', index)">&times;</span>
                      <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.src + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!--    FOR IMAGE SLIDER OF ATTACHMENTS   -->
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    >
    </image-slider>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import CompanyObjectiveEvidenceMixin from "@/mixins/CompanyObjectiveEvidenceMixin";
import {DataService} from "@/services/DataService";
import CompanyNonConformityMixin from "@/mixins/CompanyNonConformityMixin";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";

export default {
  name : 'CompanyOEAttachments',
  components: {ImageSlider},
  mixins : [CompanyNonConformityMixin, CompanyObjectiveEvidenceMixin, ImageSliderMixin],
  data() {
    return {
      toEdit : false,
      objectiveEvidenceId : null,
      attachments: {
        before : {
          images : []
        },
        after : {
          images : []
        }
      },
      imageIdsToDelete : {
        before : [],
        after : []
      },
      selectedImageIndex: 0,
      showSlider: false,
      swalOptions: {
        'title': 'Sweet Alert Title',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      },
    }
  },
  methods: {
    ...mapActions([
      'getCompanyNonConformities',
      'updateCompanyNonConformities',
      'deleteCompanyOeAttachments'
    ]),
    resetImageIdsHolders() {
      this.imageIdsToDelete.before = [];
      this.imageIdsToDelete.after = [];
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.resetImageIdsHolders();
        await this.initialize();
        // initialize again the saved images
        this.toEdit = false;
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.resetImageIdsHolders();
        await this.initialize();
        // initialize again the saved images
        this.toEdit = false;
      }
    },
    async submit() {
      swal.fire({...this.swalOptions, ...{title: 'SAVING CHANGES FOR THE ATTACHMENTS, PLEASE WAIT...'}});
      swal.showLoading();

      let errorUploadingResponse = {
        result : true,
        error_message  : null
      };
      if (this.newBeforeFiles.length > 0) {
        let beforeFormData = new FormData();
        this.newBeforeFiles.forEach((image, index) => {
          beforeFormData.append('before[' +  index + ']', image.file);
        });

        const updateImageResponse = await this.updateOEImageAttachment({
          id : this.objectiveEvidenceId,
          type : 'before',
          data : beforeFormData
        });
        if (updateImageResponse.result === false) {
          errorUploadingResponse.result = false;
          errorUploadingResponse.error_message = updateImageResponse.message;
          return AlertService.errorAlert(updateImageResponse.message, 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE');
        }
      }

      if (this.newAfterFiles.length > 0) {
        let afterFormData = new FormData();
        this.newAfterFiles.forEach((image, index) => {
          afterFormData.append('after[' +  index + ']', image.file);
        });
        const updateImageResponse = await this.updateOEImageAttachment({
          id : this.objectiveEvidenceId,
          type : 'after',
          data : afterFormData
        });
        if (updateImageResponse.result === false) {
          errorUploadingResponse.result = false;
          errorUploadingResponse.error_message = updateImageResponse.message;
          return AlertService.errorAlert(updateImageResponse.message, 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE');
        }
      }

      if (this.imageIdsToDelete.before.length > 0) {
        await this.deleteCompanyOeAttachments({
          oe_id : this.$route.params.oe_id,
          ids : this.imageIdsToDelete.before,
          type : 'before'
        });
      }

      if (this.imageIdsToDelete.after.length > 0) {
        await this.deleteCompanyOeAttachments({
          oe_id : this.$route.params.oe_id,
          ids : this.imageIdsToDelete.after,
          type : 'after'
        });
      }
      this.resetImageIdsHolders();

      await this.getUpdatedNonConformity();
      await this.initialize();
      swal.close();
      AlertService.successAlert('SUCCESS UPLOADING ATTACHMENT', 'UPLOADING ATTACHMENT IN OBJECTIVE EVIDENCE')
      this.toEdit = false;
    },
    addImage(type) {
      let _this = this;
      let files = this.$refs.before_attachments.files;
      if (type === 'after') {
        files = this.$refs.after_attachments.files;
      }
      for (let counter = 0; counter < files.length; counter++) {
        const file = files[counter];
        if (file) {
          const reader = new FileReader();
          reader.onload = function(e) {
            _this.attachments[type].images.push({
              id : null,
              src : e.target.result,
              file : file,
              name : 'test'
            });
          };
          reader.readAsDataURL(file);
        }
      }

      this.$refs.before_attachments.value = null;
      this.$refs.after_attachments.value = null;
    },
    deleteImage(type, index) {
      const imageToDelete = this.attachments[type].images[index];
      if (imageToDelete.id !== null) {
        this.imageIdsToDelete[type].push(imageToDelete.id);
      }
      this.attachments[type].images.splice(index, 1)
    },
    convertImageUrlToBase64(imageUrl) {
      return fetch(imageUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        });
    },
    async initialize() {
      this.attachments.before.images = [];
      this.attachments.after.images = [];
      const objectiveEvidence = this.companyNonConformities[this.$route.params.type][this.$attrs['active-ncr-index']].objective_evidences[this.$attrs['active-oe-index']];
      this.objectiveEvidenceId = objectiveEvidence.id;

      objectiveEvidence.before.forEach(data => {
        this.attachments.before.images.push({
          id : data.id,
          src : data.base64,
          file : null,
          name : 'test'
        });
      });

      objectiveEvidence.after.forEach(data => {
        this.attachments.after.images.push({
          id : data.id,
          src : data.base64,
          file : null,
          name : 'test'
        });
      });
    },
  },
  created() {
    this.initialize();
  },
  computed : {
    ...mapGetters([
      'companyNonConformities'
    ]),
    newBeforeFiles() {
      return this.attachments.before.images.filter(image => {
        return image.file !== null;
      });
    },
    newAfterFiles() {
      return this.attachments.after.images.filter(image => {
        return image.file !== null;
      })
    },
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      return this.imageIdsToDelete.before.length > 0 || this.imageIdsToDelete.after.length > 0 || this.newBeforeFiles.length > 0 || this.newAfterFiles.length > 0;
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}

.close-selected-image {
  color: red;
  font-weight: bold;
  font-size: 40px;
  position: absolute;
  top: -11px;
  right: 0;
  opacity: 0.7;
}

.close-selected-image:hover {
  opacity: 1;
}

#ia-attachment-card:hover {
  background-color: #dae8f4;
  transition: 0.5s;
}
</style>
